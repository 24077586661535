export const config = {
    upiId: process.env.REACT_APP_UPIID,
    name: process.env.REACT_APP_NAME,
    data : [
        {
            id: 1,
            name: 'Snacks ( Pazhampori, uzhunnu vada, paripu vada, ...',
            price: 10,
            qty: 0,
            sum: 0
        },
        {
            id: 2,
            name: 'Drinks (leamon Tea, Geera Soda).',
            price: 10,
            qty: 0,
            sum: 0
    
        },
        {
            id: 3,
            name: 'Egg puffs.',
            price: 17,
            qty: 0,
            sum: 0
        }
    
    ]
}


